import { themeGet } from "@styled-system/theme-get"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import styled from "styled-components"
import { Box } from "../core"
import { IconExpand } from "../icons"

export function CollapseSection({ title, accent, defaultCollapsed, children }) {
  const [isCollapsed, setIsCollapsed] = React.useState(defaultCollapsed)

  return (
    <SectionWrapper>
      <HeaderWrapper onClick={() => setIsCollapsed(!isCollapsed)}>
        <Title>{title}</Title>
        <IconWrapper isCollapsed={isCollapsed}>
          <IconExpand />
        </IconWrapper>
      </HeaderWrapper>
      <AnimatePresence initial={false}>
        {!isCollapsed && (
          <motion.div
            key="body"
            initial={{ opacity: 0, y: -10, height: 0 }}
            animate={{ opacity: 1, y: 0, height: "auto" }}
            exit={{ opacity: 0, y: -10, height: 0, transition: { opacity: { duration: 0.1 } } }}
            // transition={{ opacity: { duration: 0.1 } }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </SectionWrapper>
  )
}

const SectionWrapper = styled(Box).attrs(() => ({
  pt: ["16px"],
  my: ["64px"],
}))`
  border-top: 2px solid ${themeGet("black")}; ;
`

const HeaderWrapper = styled(Box).attrs(() => ({
  mb: ["16px"],
}))`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

const Title = styled(Box).attrs(() => ({
  as: "h3",
}))``

const IconWrapper = styled(Box).attrs(() => ({
  py: ["8px"],
  ml: ["8px"],
}))`
  & > svg {
    transform: rotate(${(props) => (props.isCollapsed ? "0deg" : "180deg")});
    transition: transform 200ms ease;
  }
`
