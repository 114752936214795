import React from "react"
import styled from "styled-components"
import { links } from "../../data/site"
import { CollapseSection } from "../content/collapse-section"
import { Box } from "../core"
import { SmartLink } from "../smartlink"
import { NoteCard } from "./note-card"

export function NotesSection({ notesData }) {
  return (
    <CollapseSection title="Contributor Notes">
      {notesData.map((note) => (
        <NoteContainer key={note.id}>
          <NoteCard note={note} />
        </NoteContainer>
      ))}
      {!notesData.length && (
        <Placeholder>
          <SmartLink label="Become a Contributor" path={links.contribute.path} /> to add your notes.
        </Placeholder>
      )}
    </CollapseSection>
  )
}

const NoteContainer = styled(Box).attrs(() => ({
  mb: ["32px"],
}))`
  &:last-child {
    margin-bottom: 0;
  }
`

const Placeholder = styled(Box)`
  a {
    text-decoration: underline;
  }
`
