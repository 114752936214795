import { themeGet } from "@styled-system/theme-get"
import React from "react"
import styled from "styled-components"
import { getColor } from "../../lib/colors"
import { Box, Button } from "../core"

export function ProductReview({ product }) {
  const { colorPrimary, title, slug } = product
  const reviewUrl = "https://healthtechco.typeform.com/to/Fp6KpMgH#product=" + encodeURIComponent(title)

  return (
    <CardWrapper>
      <Box>Have you tried this? Share your notes.</Box>
      <StyledButton
        as="a"
        href={reviewUrl}
        bgColor={getColor(colorPrimary).background}
        textColor={getColor(colorPrimary).foreground}
      >
        Review {title}
      </StyledButton>
    </CardWrapper>
  )
}

const CardWrapper = styled(Box).attrs(() => ({
  as: "ul",
  padding: ["16px", "24px"],
  mb: ["24px"],
}))`
  border: 2px solid ${themeGet("colors.black")};
`

const StyledButton = styled(Button).attrs(() => ({
  size: "medium",
  fontSize: 3,
  mt: 4,
  width: "100%",
}))`
  &,
  &:hover {
    background-color: ${(props) => props.bgColor};
    color: ${(props) => props.textColor};
  }

  &:hover {
    opacity: 0.8;
  }
`

const RatingHeader = styled(Box).attrs(() => ({}))`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ScoreWrapper = styled(Box).attrs(() => ({}))`
  display: flex;
`

const ScoreDot = styled(Box).attrs(() => ({
  width: ["14px"],
  height: ["14px"],
  ml: ["4px"],
}))`
  border-radius: 100px;
  border: 2px solid ${themeGet("colors.black")};
  background: ${(props) => (props.filled ? themeGet("colors.yellow._700") : "white")};
`

const Title = styled(Box).attrs(() => ({
  fontSize: [3, 3],
  fontWeight: 2,
  lineHeight: 0,
}))``

const Subtitle = styled(Box).attrs(() => ({
  fontSize: [2],
  mt: ["8px"],
}))`
  color: ${themeGet("colors.text.secondary")};
`
