import { themeGet } from "@styled-system/theme-get"
import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import { BlockContent } from "../components/content/block-content"
import { BulletSection } from "../components/content/bullet-section"
import { CollapseSection } from "../components/content/collapse-section"
import { Box } from "../components/core"
import { Lead } from "../components/core/typography"
import { IconCon, IconPro, IconTip } from "../components/icons"
import { Layout } from "../components/layout"
import { NotesSection } from "../components/note/notes-section"
import { ProductHero } from "../components/product/product-hero"
import { ProductRating } from "../components/product/product-rating"
import { ProductReview } from "../components/product/product-review"
import { SEO } from "../components/seo"
import { mapEdgesToNodes } from "../lib/helpers"

export const pageQuery = graphql`
  query ProductTemplateQuery($id: String!) {
    product: sanityProduct(id: { eq: $id }) {
      id
      slug {
        current
      }
      title
      short_description
      colorPrimary
      colorSecondary
      introduction
      _rawOurTake
      _rawPros
      _rawCons
      _rawTips
      _rawTopics
      topics {
        topic {
          title
          _id
        }
      }
      availability
      primary_purchase_location
      company_purchase_label
      annual_price
      monthly_price
      free
      price
      title
      url_amazon
      url_app_store
      url_company
      url_google_play_store
      url_sephora
      ratings {
        rating {
          title
        }
        score
        _rawBody
      }
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
      }
    }
    notes: allSanityNote(filter: { product: { id: { eq: $id } } }) {
      edges {
        node {
          recommend
          snippet
          length_value
          length_unit
          id
          _rawBody
          _rawTips
          contributor {
            fname
            lname
            age
            gender
            bio
            location
          }
        }
      }
    }
  }
`

export default function ProductTemplate({ data: { product, notes } }) {
  const notesNodes = mapEdgesToNodes(notes)

  let notesWithTip = []
  if (notesNodes) {
    notesWithTip = notesNodes.filter(({ _rawTips }) => _rawTips)
  }

  const rawTopics = product._rawTopics || []
  const topics = rawTopics.map(({ body, topic: { _ref } }) => {
    const { title } = product.topics.find((x) => x.topic._id === _ref).topic

    return {
      title,
      body,
    }
  })

  return (
    <Layout>
      <SEO title={product.title} description={product.description} />

      <ProductHero product={product} />
      <ContentWrapper>
        <MainWrapper>
          <Lead>{product.introduction}</Lead>
          <Box display={["block", "block", "none"]}>
            <ProductRating product={product} />
            <ProductReview product={product} />
          </Box>
          <BlockContent blocks={product._rawOurTake} serif />
          <BulletSection title="Pros" accent="#008234" listIcon={<IconPro />} listData={product._rawPros} />
          <BulletSection title="Cons" accent="#A03535" listIcon={<IconCon />} listData={product._rawCons} />
          <BulletSection title="Tips" accent="#F9C100" listIcon={<IconTip />} listData={product._rawTips} />
          {topics.map(({ title, body }) => (
            <CollapseSection key={title} title={title} defaultCollapsed>
              <BlockContent blocks={body} serif />
            </CollapseSection>
          ))}
          <NotesSection notesData={notesNodes} />
        </MainWrapper>
        <SidebarWrapper>
          <Box display={["none", "none", "block"]} position="sticky" top="32px">
            <ProductRating product={product} />
            <ProductReview product={product} />
          </Box>
        </SidebarWrapper>
      </ContentWrapper>
    </Layout>
  )
}

const ContentWrapper = styled(Box).attrs(() => ({
  paddingTop: ["32px", "64px"],
}))`
  @media (min-width: ${themeGet("breakpoints.md")}) {
    display: grid;
    grid-gap: 64px;
    grid-template-columns: auto 400px;
  }
`

const MainWrapper = styled(Box).attrs(() => ({}))``

const SidebarWrapper = styled(Box).attrs(() => ({}))``
