import { themeGet } from "@styled-system/theme-get"
import React from "react"
import styled from "styled-components"
import { BlockContent } from "../content/block-content"
import { Box } from "../core"

export function ProductRating({ product }) {
  return (
    <CardWrapper>
      {product.ratings.length === 0 && (
        <Box>We're still reviewing this product – subscribe to our newsletter to get updates.</Box>
      )}
      {product.ratings.map(({ rating: { title }, score, _rawBody }) => (
        <RatingWrapper key={title}>
          <RatingHeader>
            <Title>{title}</Title>
            <ScoreWrapper>
              {[1, 2, 3, 4].map((number) => (
                <ScoreDot key={number} filled={number <= score} />
              ))}
            </ScoreWrapper>
          </RatingHeader>
          <Subtitle>
            <BlockContent blocks={_rawBody} />
          </Subtitle>
        </RatingWrapper>
      ))}
    </CardWrapper>
  )
}

const CardWrapper = styled(Box).attrs(() => ({
  as: "ul",
  padding: ["16px", "24px"],
  mb: ["24px"],
}))`
  border: 2px solid ${themeGet("colors.black")};
`

const RatingWrapper = styled(Box).attrs(() => ({
  as: "li",
  pb: ["16px", "20px"],
  mb: ["16px", "20px"],
}))`
  border-bottom: 1px solid ${themeGet("colors.grayRGBA._400")};

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
  }
`

const RatingHeader = styled(Box).attrs(() => ({}))`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ScoreWrapper = styled(Box).attrs(() => ({}))`
  display: flex;
`

const ScoreDot = styled(Box).attrs(() => ({
  width: ["14px"],
  height: ["14px"],
  ml: ["4px"],
}))`
  border-radius: 100px;
  border: 2px solid ${themeGet("colors.black")};
  background: ${(props) => (props.filled ? themeGet("colors.yellow._700") : "white")};
`

const Title = styled(Box).attrs(() => ({
  fontSize: [3, 3],
  fontWeight: 2,
  lineHeight: 0,
}))``

const Subtitle = styled(Box).attrs(() => ({
  fontSize: [2],
  mt: ["8px"],
}))`
  color: ${themeGet("colors.text.secondary")};
`
