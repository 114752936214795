import { themeGet } from "@styled-system/theme-get"
import React from "react"
import styled from "styled-components"
import { getContributorColor } from "../../lib/colors"
import { BlockContent } from "../content/block-content"
import { Box } from "../core"

export const NoteCard = ({ note }) => {
  if (!note.contributor || !note._rawBody) return null
  const { fname, lname, gender, location, age } = note.contributor

  function getName(abbr) {
    if (fname) {
      if (lname) {
        if (abbr) {
          return `${fname} ${lname && lname.charAt(0) + "."}`
        } else {
          return `${fname} ${lname}`
        }
      } else {
        if (abbr) {
          return fname
        } else {
          return fname + Math.floor(Math.random() * Math.floor(100))
        }
      }
    } else {
      if (abbr) {
        return "Anonymous"
      } else {
        return "Anonymous" + Math.floor(Math.random() * Math.floor(100))
      }
    }
  }

  function getSubtitle() {
    const values = [age, gender, location].filter((value) => !!value)
    return values.join(", ")
  }

  const AvatarColor = getContributorColor(getName(false))

  return (
    <Wrapper>
      <HeaderWrapper display="flex" alignItems="center">
        <Avatar backgroundColor={AvatarColor.background} color={AvatarColor.foreground}></Avatar>
        <Title>{getName(true)}</Title>
        <Subtitle>{getSubtitle()}</Subtitle>
      </HeaderWrapper>
      <CommentWrapper>
        <BlockContent blocks={note._rawBody} />
      </CommentWrapper>
    </Wrapper>
  )
}

const Wrapper = styled(Box).attrs(() => ({
  // padding: ["24px"],
}))`
  /* border: 2px solid ${themeGet("colors.black")}; */
`

const HeaderWrapper = styled(Box).attrs(() => ({
  display: "flex",
  alignItems: "center",
  mb: ["8px"],
}))``

const Avatar = styled(Box).attrs(() => ({
  width: ["24px"],
  height: ["24px"],
  mr: ["12px"],
}))`
  border-radius: 100px;
  flex-shrink: 0;
`

const Title = styled(Box).attrs(() => ({
  fontSize: [3],
  fontWeight: 2,
  lineHeight: 0,
}))`
  flex-shrink: 0;
`

const Subtitle = styled(Box).attrs(() => ({
  ml: ["8px"],
  fontSize: [1],
  fontWeight: 1,
  lineHeight: 0,
  color: "text.secondary",
}))``

export const CommentWrapper = styled(Box).attrs(() => ({
  fontSize: [2],
  pl: ["36px"],
}))``
