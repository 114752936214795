import React from "react"
import styled from "styled-components"
import { Box } from "../core"
import { CollapseSection } from "./collapse-section"

export function BulletSection({ title, accent, listIcon, listData }) {
  // TODO: Make less brittle
  const rawList = listData || []
  const list = rawList.map(({ children }) => children.map(({ text }) => text))

  if (!list.length) return null

  return (
    <CollapseSection title={title} accent={accent}>
      <ul>
        {list.map((text) => (
          <ListRow key={text}>
            <ListRowIcon color={accent || "primary.default"}>{listIcon}</ListRowIcon>
            <ListTitle>{text}</ListTitle>
          </ListRow>
        ))}
      </ul>
    </CollapseSection>
  )
}

const ListRow = styled(Box).attrs(() => ({
  as: "li",
  mb: ["16px", "20px"],
}))`
  display: flex;

  &:last-child {
    margin-bottom: 0;
  }
`

const ListRowIcon = styled(Box).attrs(() => ({
  pt: ["3px"],
  mr: ["12px"],
}))``

const ListTitle = styled(Box).attrs(() => ({
  as: "p",
  fontSize: [3],
  // fontWeight: 2,
  // lineHeight: 0,
}))``
